import React from "react";
import { flexRender } from "@tanstack/react-table";
import { classNames } from "../../../utils/classNames";
function range(start, end) {
    return Array.from({ length: end - start + 1 }, (_, i) => i);
}
export const Table = ({ table, minRows = 8, visibleRows, className, reportType }) => {
    const { rows } = table.getRowModel();

    return (
        <table className=" w-full">
            <thead className={``}>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                            return (
                                <th
                                    className={classNames(
                                        header.column.getCanSort()
                                            ? "cursor-pointer select-none "
                                            : "",

                                        header.column.columnDef?.meta
                                            ?.headerClassName,
                                        header.column.columnDef?.meta
                                            ?.className,
                                        header.column.columnDef?.meta
                                            ?.disablePadding
                                            ? ""
                                            : "px-4 py-3",
                                        "!border-x-0 font-medium",
                                    )}
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    style={{ width: header.getSize() }}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </th>
                            );
                        })}
                    </tr>
                ))}
            </thead>
            <tbody>
                {rows.map((row, index) => {
                    const { existing, account } = row.original;

                    if (!existing && reportType === "IG__V1") {
                        return (
                            <tr
                                key={row.id}
                                className="text-center border-y-2 border-gray-200"
                            >
                                <td className="px-4 py-3 text-sm text-center">
                                    {flexRender(
                                        row.getVisibleCells()[0].column
                                            .columnDef.cell,
                                        row.getVisibleCells()[0].getContext(),
                                    )}
                                </td>

                                <td className="px-4 py-3">
                                    <div
                                        className="-my-2 mx-auto border border-gray-50 text-center rounded-full bg-[#F2F4F7]"
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                        }}
                                    />
                                </td>

                                <td
                                    className="px-4 py-3 text-start text-[#7B7B7B] text-sm"
                                    style={{
                                        maxWidth: "70px",
                                    }}
                                >
                                    <a
                                        className="line-clamp-1 truncate underline underline-offset-1"
                                        href={`https://www.instagram.com/${account}/`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        @{account}
                                    </a>
                                </td>

                                <td
                                    colSpan={6}
                                    className="px-4 py-3 text-start text-[#7B7B7B] text-xs"
                                >
                                    This account is no longer available on
                                    Instagram.
                                </td>
                            </tr>
                        );
                    }

                    return (
                        <tr
                            key={row.id}
                            className={classNames(
                                `py-3 text-center text-sm font-normal text-primary-900`,
                                visibleRows &&
                                    visibleRows - 1 < index &&
                                    "pointer-events-none blur-sm",
                            )}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <td
                                    key={cell.id}
                                    className={classNames(
                                        `border-t-2 border-gray-200`,
                                        cell.column.columnDef?.meta
                                            ?.disablePadding
                                            ? ""
                                            : "px-4 py-3",
                                        cell.column.columnDef?.meta
                                            ?.cellClassName,
                                        cell.column.columnDef?.meta?.className,
                                    )}
                                >
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                    )}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
