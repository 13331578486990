import { useReadMore } from "../../../hooks/useReadMore";
import { classNames } from "../../../utils/classNames";
import { getHorizontalBarOptions } from "../_generic/chartOptions";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import ReactEcharts from "echarts-for-react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { certificateImages } from "../../../utils/constants";
const BrandPositioning = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    const { clamped, handleClick, showButton, containerRef } = useReadMore();
    if (!slideContent) slideContent = {};
    let {
        brand = "ERROR",
        brandInfo = "ERROR",
        logo = "ERROR",
        attributes = [],
        certificates = [],
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full min-h-[690px] bg-[#F0ECE6] py-10 px-12">
                <h1 className=" text-4xl font-medium">{"Brand Positioning"}</h1>
                <p className="mt-4 text-sm font-medium">
                    BRAND: <span className="text-eyvablue">{brand}</span>{" "}
                </p>

                <div className="mt-5 flex items-start space-x-10 text-sm leading-8 bg-white px-8 py-6 rounded-md text-black">
                    <img
                        src={logo}
                        alt="logo"
                        className="rounded-full w-14 h-14"
                    />
                    <div>
                        <div
                            ref={containerRef}
                            style={{ maxHeight: clamped ? "150px" : "none" }}
                            className={classNames(
                                "markdown !max-w-none prose-h1:text-base",
                                clamped ? "clamp" : "",
                                // s["first-header"],
                            )}
                        >
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                {brandInfo}
                            </ReactMarkdown>
                        </div>
                        {showButton && (
                            <button onClick={handleClick}>
                                <p className="pt-2 text-sm font-medium text-eyvablue hover:text-blue-800">
                                    {clamped ? "Read more" : "View less"}
                                </p>
                            </button>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-5 my-5">
                    <div className="">
                        <p className="text-sm font-medium mb-3">
                            TOP ATTRIBUTES
                        </p>
                        {attributes.length === 0 ? (
                            <div className=" text-sm w-full text-center bg-[#ECE3D6] h-[350px] border border-[#DDC7A8] rounded-md flex flex-col justify-center ">
                                <div className="max-w-sm mx-auto space-y-3">
                                    <img
                                        src={"/attributes-empty-state.png"}
                                        alt="empty-card"
                                        className=" w-18 h-18 rounded-full object-contain mx-auto"
                                    />
                                    <p className="font-semibold">
                                        No Listed Attributes on E-Tailers
                                    </p>
                                    <p className="text-gray-600">
                                        The products from this brand do not have
                                        any attributes listed on e-tailers such
                                        as Douglas, Notino, DM, Müller,
                                        Rossmann, Flaconi, Niche Beauty,
                                        Net-A-Porter, and Sephora in Germany.
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white px-4 py-3 rounded-md h-[350px] m-auto">
                                <ReactEcharts
                                    option={getHorizontalBarOptions({
                                        data: attributes,
                                        color: "#DC715A",
                                    })}
                                    opts={{ renderer: "svg" }}
                                    style={{ width: "560px", height: "340px" }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="">
                        <p className="text-sm font-medium mb-3">
                            TOP CERTIFICATES
                        </p>
                        {certificates.length === 0 ? (
                            <div className=" text-sm w-full text-center bg-[#ECE3D6] h-[350px] border border-[#DDC7A8] rounded-md flex flex-col justify-center ">
                                <div className="max-w-sm mx-auto space-y-3">
                                    <img
                                        src={"/certificate-empty-state.png"}
                                        alt="empty-card"
                                        className=" w-14 h-14 object-contain mx-auto"
                                    />
                                    <p className="font-semibold">
                                        No Certifications Detected
                                    </p>
                                    <p className="text-gray-600">
                                        We were unable to detect any
                                        certifications for the products listed
                                        on e-tailers such as Douglas, Notino,
                                        DM, Müller, Rossmann, Flaconi, Niche
                                        Beauty, Net-A-Porter, and Sephora in
                                        Germany
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white px-4 py-3 rounded-md h-[350px]">
                                <div className="grid grid-cols-4 gap-5">
                                    {certificates.map((certificate, index) => (
                                        <div
                                            key={index}
                                            className="space-y-2 text-center"
                                        >
                                            <div className="rounded-full border border-gray-200 p-1 w-fit mx-auto">
                                                <img
                                                    src={
                                                        certificateImages[
                                                            certificate.name
                                                        ]
                                                    }
                                                    alt={certificate.name}
                                                    className="w-12 h-12"
                                                />
                                            </div>
                                            <p className="text-sm font-medium">
                                                {certificate.name}
                                            </p>
                                            <p className="text-xs">
                                                {certificate.value}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {/* <ReactEcharts
                            option={getHorizontalBarOptions({
                                data: attributes,
                                color: "#DC715A",
                            })}
                            opts={{ renderer: "svg" }}
                            style={{ width: "560px", height: "340px" }}
                        /> */}
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default BrandPositioning;
