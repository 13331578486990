export const IS_DEV = process.env.REACT_APP_ENVIRONMENT === "DEV";
export const APP_URL = IS_DEV ? "http://dev2.eyva.ai/" : "https://app.eyva.ai/";

export const certificateImages = {
    PeTA: `/certificates/webp/PeTA.webp`,
    ICADA: `/certificates/webp/ICADA.webp`,
    "leaping bunny": `/certificates/webp/leaping bunny.webp`,
    FSC: `/certificates/webp/FSC.webp`,
    "COSMOS Organic": `/certificates/webp/COSMOS Organic.webp`,
    ECOCERT: `/certificates/webp/ECOCERT.webp`,
    CSE: `/certificates/webp/CSE.webp`,
    USDA: `/certificates/webp/USDA.webp`,
    BDIH: `/certificates/webp/BDIH.webp`,
    EcoControl: `/certificates/webp/EcoControl.webp`,
    COSMEBIO: `/certificates/webp/COSMEBIO.webp`,
    NATRUE: `/certificates/webp/NATRUE.webp`,
    "COSMOS Natural": `/certificates/webp/COSMOS Natural.webp`,
    CPNP: `/certificates/webp/CPNP.webp`,
    Veganblume: `/certificates/webp/Veganblume.webp`,
    DAAB: `/certificates/webp/DAAB.webp`,
};
