import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const End = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    const { h1 = "ERROR" } = slideContent;

    return (
        <Layout showFull={showFull} className="bg-eyvagray-700">
            <div className="text-white w-full min-h-[690px] flex items-center justify-center">
                <p className="text-base font-normal font-ibm">{h1}</p>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
                withBackground={false}
                newui={true}
                className="text-white"
            />
        </Layout>
    );
};

export default End;
