import { formatNumberCompact } from "../../../utils/formatNumber";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const TopBrands = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    const products = [
        {
            brand: "SESDERMA",
            products: 100,
            imageURL:
                "https://s3-alpha-sig.figma.com/img/806d/0796/a81d3e698c458db0849b5be64fb873f9?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=YrumduGxxW~ZHvsys7trLsLCtpvC~onqCbR-h6fS5JVe3qmtXpvlJb8H9X8sihCNdnfbNAzhUg5vKoQ~rJWfbe9WLtq7RsCOj7prwjuWMoAC7Qx5zNHgoZiW5gCOgevdfWRZH7cyBFqcNNyZrBRTqgQ~Es1wBtuNQaY~VVYQeOpFWCw-cS80HDOSSpTqb60AQylbWoS7Ctww4Z5hHjBp77POqEDSPl-YNr9Xc04ca6FbBzLN0pa4~Hw1QMtksOema2j1v-flSrsAttalI1Mig1oZVfCFHqxahRxn1kyewDhA401M7cdsLWduYRDE9sDceLjKzm53r5SkvO4u2EAW9g__",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tincidunt vulputate risus nec efficitur. Vivamus non leo eleifend.",
        },
        {
            brand: "SESDERMA",
            products: 200,
            imageURL:
                "https://s3-alpha-sig.figma.com/img/806d/0796/a81d3e698c458db0849b5be64fb873f9?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=YrumduGxxW~ZHvsys7trLsLCtpvC~onqCbR-h6fS5JVe3qmtXpvlJb8H9X8sihCNdnfbNAzhUg5vKoQ~rJWfbe9WLtq7RsCOj7prwjuWMoAC7Qx5zNHgoZiW5gCOgevdfWRZH7cyBFqcNNyZrBRTqgQ~Es1wBtuNQaY~VVYQeOpFWCw-cS80HDOSSpTqb60AQylbWoS7Ctww4Z5hHjBp77POqEDSPl-YNr9Xc04ca6FbBzLN0pa4~Hw1QMtksOema2j1v-flSrsAttalI1Mig1oZVfCFHqxahRxn1kyewDhA401M7cdsLWduYRDE9sDceLjKzm53r5SkvO4u2EAW9g__",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tincidunt vulputate risus nec efficitur. Vivamus non leo eleifend.",
        },
        {
            brand: "SESDERMA",
            products: 300,
            imageURL:
                "https://s3-alpha-sig.figma.com/img/806d/0796/a81d3e698c458db0849b5be64fb873f9?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=YrumduGxxW~ZHvsys7trLsLCtpvC~onqCbR-h6fS5JVe3qmtXpvlJb8H9X8sihCNdnfbNAzhUg5vKoQ~rJWfbe9WLtq7RsCOj7prwjuWMoAC7Qx5zNHgoZiW5gCOgevdfWRZH7cyBFqcNNyZrBRTqgQ~Es1wBtuNQaY~VVYQeOpFWCw-cS80HDOSSpTqb60AQylbWoS7Ctww4Z5hHjBp77POqEDSPl-YNr9Xc04ca6FbBzLN0pa4~Hw1QMtksOema2j1v-flSrsAttalI1Mig1oZVfCFHqxahRxn1kyewDhA401M7cdsLWduYRDE9sDceLjKzm53r5SkvO4u2EAW9g__",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tincidunt vulputate risus nec efficitur. Vivamus non leo eleifend.",
        },
        {
            brand: "SESDERMA",
            products: 400,
            imageURL:
                "https://s3-alpha-sig.figma.com/img/806d/0796/a81d3e698c458db0849b5be64fb873f9?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=YrumduGxxW~ZHvsys7trLsLCtpvC~onqCbR-h6fS5JVe3qmtXpvlJb8H9X8sihCNdnfbNAzhUg5vKoQ~rJWfbe9WLtq7RsCOj7prwjuWMoAC7Qx5zNHgoZiW5gCOgevdfWRZH7cyBFqcNNyZrBRTqgQ~Es1wBtuNQaY~VVYQeOpFWCw-cS80HDOSSpTqb60AQylbWoS7Ctww4Z5hHjBp77POqEDSPl-YNr9Xc04ca6FbBzLN0pa4~Hw1QMtksOema2j1v-flSrsAttalI1Mig1oZVfCFHqxahRxn1kyewDhA401M7cdsLWduYRDE9sDceLjKzm53r5SkvO4u2EAW9g__",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tincidunt vulputate risus nec efficitur. Vivamus non leo eleifend.",
        },
    ];

    const { h1 = "ERROR", h2 = "ERROR", table = [] } = slideContent;

    return (
        <Layout showFull={showFull} className="bg-eyvalight">
            <div className="text-eyvagray-700 w-full min-h-[690px] p-12">
                <p className="text-4xl font-normal font-gosha leading-[44px] mb-3 uppercase">
                    {h1}
                </p>
                <p className="text-sm font-normal font-ibm leading-[24px]">
                    {h2}
                </p>

                <div className="grid grid-cols-4 gap-3 mt-16 w-full">
                    {table.map((item, index) => (
                        <div
                            key={`${item.brand}-${index}`}
                            className="flex flex-col gap-2 border justify-center border-eyvagray-400 rounded-[20px] bg-white space-y-3 h-full"
                        >
                            <div className="flex flex-row items-center justify-between font-ibm pt-4 px-4">
                                <p className="text-eyvagray-700 text-sm font-medium">
                                    {item.brand}
                                </p>
                                <p className="text-eyvagray-600 text-xs font-normal">
                                    {formatNumberCompact(item.products)}{" "}
                                    PRODUCTS
                                </p>
                            </div>
                            <div className="flex items-center w-full justify-center">
                                <img
                                    src={item.imageURL}
                                    alt={item.brand}
                                    className="size-44 object-contain"
                                />
                            </div>
                            <hr className="w-full border-t border-eyvagray-400" />
                            <div className="px-4 pb-4 overflow-hidden">
                                <p className="text-eyvagray-700 text-sm font-normal font-inter line-clamp-5 h-[100px]">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
                withBackground={false}
                newui={true}
                dark
            />
        </Layout>
    );
};

export default TopBrands;
