import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { CircularProgress } from "../_generic/CircularProgress";
import { formatNumber, formatNumberCompact } from "../../../utils/formatNumber";

const Overview = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    const {
        h1 = "ERROR",
        products = 0,
        colouredProducts = 0,
        colouredPercentage = 0,
        bwProducts = 0,
        bwPercentage = 0,
    } = slideContent;

    return (
        <Layout showFull={showFull} className="bg-eyvalight">
            <div className="text-eyvagray-700 w-full min-h-[690px] p-12">
                <p className="text-4xl font-normal font-gosha leading-[44px] mb-3 uppercase">
                    {h1}
                </p>

                <div className="flex flex-row items-center space-x-10 justify-center mt-20 w-full">
                    <div className="flex flex-col items-center justify-center font-ibm w-[351px] h-[377px] border-eyvagray-400 border rounded-[20px] bg-white">
                        <div className="w-full py-3 text-center">
                            <p className="uppercase font-ibm text-sm font-medium">
                                {formatNumberCompact(products)} PRODUCTS IN
                                TOTAL
                            </p>
                        </div>
                        <hr className="w-full border-eyvagray-400" />
                        <div className="flex flex-col items-center justify-center flex-grow">
                            <CircularProgress
                                percentage={colouredPercentage}
                                size={240}
                                strokeWidth={15}
                            >
                                <div className="text-center">
                                    <p className="text-[67px] font-normal">
                                        {colouredPercentage}%
                                    </p>
                                    <p className="uppercase text-[10px] mt-1">
                                        PRODUCTS WITH COLORS
                                    </p>
                                </div>
                            </CircularProgress>
                        </div>
                    </div>
                    <div className="text-left space-y-4 font-inter font-normal text-sm w-[351px]">
                        <p>
                            <span className="text-eyvapurple font-bold">
                                {formatNumberCompact(colouredProducts)}
                            </span>{" "}
                            products{" "}
                            <span className="text-eyvaorange font-bold">
                                ({colouredPercentage}%)
                            </span>{" "}
                            use{" "}
                            <span className="text-eyvablue font-bold">
                                colors
                            </span>{" "}
                            in their image.
                        </p>
                        <p>
                            <span className="font-bold">
                                {formatNumberCompact(bwProducts)}
                            </span>{" "}
                            products (
                            <span className="font-bold">{bwPercentage}%</span>)
                            use only{" "}
                            <span className="font-bold">black, white</span> or{" "}
                            <span className="font-bold">shades of grey</span> in
                            their image.
                        </p>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
                withBackground={false}
                newui={true}
                dark
            />
        </Layout>
    );
};

export default Overview;
