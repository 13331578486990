import { classNames } from "../../../utils/classNames";

const Footer = ({
    reportName,
    // reportVersion,
    reportCreatedAt,
    slideName,
    reportLang,
    slideCounter,
    withBackground = true,
    dark = false,
    newui = false,
    className,
}) => {
    const createdAtText = reportLang === "en" ? "Created at " : "Erstellt am ";


    let slideCountText
    if (slideCounter?.index && slideCounter?.count) {
        slideCountText = `${slideCounter?.index
            .toString()
            .padStart(2, "0")}/${slideCounter?.count.toString().padStart(2, "0")}`;
    } else {
        slideCountText = "";
    }

    return (
        <div
            className={classNames(
                `w-[1280px] h-[30px] flex flex-row py-0 px-[30px] box-border items-center justify-center min-h-[30px] text-xs text-white`,
                withBackground ? "bg-eyvablue" : "",
                dark ? "text-black" : "",
                newui
                    ? "text-eyvagray-500 border-t border-eyvagray-400 font-ibm"
                    : "",
                className,
            )}
        >
            <div className="w-[1124px] flex flex-row items-center justify-end gap-[173px]">
                <div className="relative flex-1 font-medium">
                    {slideCountText} {slideName}
                </div>
                <div className="relative font-medium text-right">
                    {reportName} - {createdAtText}
                    {reportCreatedAt
                        ? reportCreatedAt.split("T")[0]
                        : "XXXX-XX-XX"}
                </div>
            </div>
            <div className="w-[69px] flex flex-row py-0 pr-[5px] pl-4 font-poppins box-border items-center justify-end text-5xs">
                <div className="relative font-medium">powered by</div>
            </div>
            <div className="h-[12.75px] flex flex-col pt-0.5 px-0 pb-0 box-border items-center justify-between">
                <img
                    className="relative w-[35.68px] h-[11.38px]"
                    alt=""
                    src={dark ? "/logo_black.png" : "/eyva_logo_footer.svg"}
                />
            </div>
        </div>
    );
};

export default Footer;
