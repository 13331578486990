import React from "react";

export const CircularProgress = ({
    percentage = 0,
    size = 240,
    strokeWidth = 15,
    foreground = "#DE2F1E",
    background = "#D2D2D2",
    children,
}) => {
    return (
        <div
            className="relative rounded-full"
            style={{
                width: size,
                height: size,
                background: `conic-gradient(
                    ${foreground} 0% ${percentage}%,
                    ${background} ${percentage}% 100%
                )`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transform: "rotate(-90deg)",
            }}
        >
            <div
                className="rounded-full bg-white"
                style={{
                    width: size - strokeWidth * 2,
                    height: size - strokeWidth * 2,
                    transform: "rotate(90deg)",
                }}
            >
                <div className="h-full w-full flex items-center justify-center">
                    {children}
                </div>
            </div>
        </div>
    );
};
