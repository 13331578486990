import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { formatNumberCompact } from "../../../utils/formatNumber";

const Table = ({ table, sortedBy }) => {
    return (
        <div className="font-ibm w-full border-eyvagray-400 border overflow-hidden rounded-[10px] bg-white">
            <p className="text-sm font-medium font-ibm leading-[24px] uppercase text-center py-3">
                COLORS SORTED BY{" "}
                {sortedBy === "products"
                    ? "NUMBER OF PRODUCTS"
                    : "AVERAGE RATING"}
            </p>
            <table className="w-full border-collapse">
                <thead className="border-b border-t border-eyvagray-400 text-eyvagray-400">
                    <tr className="text-eyvagray-600">
                        <th class="text-left px-4 py-2 text-[10px] font-ibm font-normal uppercase border-r border-eyvagray-400">
                            {" "}
                            COLOR
                        </th>
                        <th
                            class={`text-right px-4 py-2 text-[10px] font-ibm font-medium uppercase border-r ${
                                sortedBy === "products"
                                    ? "bg-[#F7F7F5] text-eyvagray-700"
                                    : ""
                            } border-eyvagray-400`}
                        >
                            {" "}
                            PRODUCTS
                        </th>
                        <th class="text-right px-4 py-2 text-[10px] font-ibm font-normal uppercase border-r border-eyvagray-400">
                            {" "}
                            NO. OF REVIEWS
                        </th>
                        <th
                            class={`text-right px-4 py-2 text-[10px] font-ibm font-medium uppercase  ${
                                sortedBy === "rating"
                                    ? "bg-[#F7F7F5] text-eyvagray-700"
                                    : ""
                            }`}
                        >
                            {" "}
                            AVERAGE RATING
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-eyvagray-400">
                    {table.map((row) => (
                        <tr
                            key={row.color}
                            className="font-inter font-normal text-sm"
                        >
                            <td className="text-left px-4 py-5 border-r border-eyvagray-400">
                                <div className="flex flex-row items-center gap-2">
                                    <div
                                        className="size-3 rounded-sm"
                                        style={{
                                            backgroundColor: row.hex,
                                        }}
                                    />
                                    <p className="line-clamp-1 max-w-[120px] capitalize">
                                        {row.color}
                                    </p>
                                </div>
                            </td>
                            <td
                                className={`text-right px-4 py-5 border-r text-black ${
                                    sortedBy === "products"
                                        ? "bg-[#F7F7F5]"
                                        : ""
                                } border-eyvagray-400`}
                            >
                                {formatNumberCompact(row.products)}
                            </td>
                            <td className="text-right px-4 py-5 border-r border-eyvagray-400">
                                {formatNumberCompact(row.reviews)}
                            </td>
                            <td
                                className={`px-4 py-5 flex flex-row items-center justify-end ${
                                    sortedBy === "rating" ? "bg-[#F7F7F5]" : ""
                                } gap-3`}
                            >
                                <div className="w-[70px] bg-[#D9D9D9] rounded-xs h-1">
                                    <div
                                        className="bg-eyvagray-700 h-1 rounded-xs"
                                        style={{
                                            width: `${(row.rating / 5) * 100}%`,
                                        }}
                                    ></div>
                                </div>
                                {row.rating.toFixed(1)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const TopColours = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    const table = [
        {
            color: "Ocean Blue Yo Sup coooooooooooool",
            hex: "#0000FF",
            products: 1500,
            reviews: 1500,
            rating: 4.5,
        },
        {
            color: "Sunset Orange",
            hex: "#FFA500",
            products: 1500,
            reviews: 1500,
            rating: 2.5,
        },
        {
            color: "Lavender Mist",
            hex: "#800080",
            products: 1500,
            reviews: 1500,
            rating: 3.5,
        },
        {
            color: "Crimson Red",
            hex: "#FF0000",
            products: 1500,
            reviews: 1500,
            rating: 5,
        },
        {
            color: "Golden Yellow",
            hex: "#FFFF00",
            products: 1500,
            reviews: 1500,
            rating: 4,
        },
        {
            color: "Emerald Green",
            hex: "#00FF00",
            products: 1500,
            reviews: 1500,
            rating: 3,
        },
        {
            color: "Steel Gray",
            hex: "#808080",
            products: 1500,
            reviews: 1500,
            rating: 2,
        },
        {
            color: "Brown",
            hex: "#A52A2A",
            products: 1500,
            reviews: 1500,
            rating: 5,
        },
        {
            color: "Black",
            hex: "#000000",
            products: 1500,
            reviews: 1500,
            rating: 4,
        },
        {
            color: "White",
            hex: "#F7F7F5",
            products: 1500,
            reviews: 1500,
            rating: 4,
        },
    ];

    const {
        h1 = "ERROR",
        h2 = "ERROR",
        sortByProducts = [],
        sortByReviews = [],
    } = slideContent;

    return (
        <Layout showFull={showFull} className="bg-eyvalight">
            <div className="text-eyvagray-700 w-full min-h-[690px] p-12">
                <p className="text-4xl font-normal font-gosha leading-[44px] mb-3 uppercase">
                    {h1}
                </p>
                <p className="text-sm font-normal font-ibm leading-[24px]">
                    {h2}
                </p>

                <div className="grid grid-cols-2 gap-9 mt-5 w-full">
                    <Table table={sortByProducts} sortedBy="products" />
                    <Table table={sortByReviews} sortedBy="rating" />
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
                withBackground={false}
                newui={true}
                dark
            />
        </Layout>
    );
};

export default TopColours;
