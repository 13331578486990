import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const TransitionTwo = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    const {
        h1 = "Explore products by color. Compare different shades, discover trends, and see how colors are used across various products.",
        topic = "ERROR",
        imageURL = "ERROR",
    } = slideContent;

    return (
        <Layout showFull={showFull} className="bg-eyvalight">
            <div className="text-eyvagray-700 w-full min-h-[690px] grid grid-cols-2 font-ibm">
                <div className="mt-8 col-span-1 p-14 flex flex-col justify-center">
                    <p className="text-sm font-normal mb-28 w-[400px] mx-5">
                        {h1}
                    </p>
                    <div className="font-normal grid grid-cols-3 gap-10">
                        <div className="col-span-1"></div>
                        <div className="col-span-2">
                            <p className="text-eyvagray-700 text-base mb-3">
                                UP NEXT
                            </p>
                            <p className="text-eyvagray-700 text-sm border-b leading-[50px] border-eyvagray-700">
                                {topic}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col col-span-1">
                    <img
                        loading="lazy"
                        srcSet={imageURL}
                        className="w-full h-full object-cover"
                        alt=""
                    />
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
                withBackground={false}
                newui={true}
                dark
            />
        </Layout>
    );
};

export default TransitionTwo;
