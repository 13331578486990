import React from "react";

import Footer from "../_generic/Footer";
import { CoverSlideLayout } from "../_generic/CoverSlideLayout";

const Cover = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
}) => {
    if (!slideContent) slideContent = {};

    const {
        h1 = "COLOR REPORT",
        imageURL = "https://veomzntynruzalwfhfmg.supabase.co/storage/v1/object/public/ice_public/report_images/CI_v2_Skincare/CI_v2_Cover_Skincare.png",
    } = slideContent;

    return (
        <CoverSlideLayout
            imageURL={imageURL}
            footer={
                <Footer
                    slideName={slideName}
                    reportName={reportName}
                    reportVersion={reportVersion}
                    reportCreatedAt={reportCreatedAt}
                    reportLang={reportLang}
                    slideCounter={slideCounter}
                    withBackground={false}
                />
            }
            dark={false}
            newui={true}
        >
            <div className="text-[138px] font-normal text-white font-gosha leading-[149px] line-clamp-1 uppercase">
                {h1}
            </div>
        </CoverSlideLayout>
    );
};

export default Cover;
