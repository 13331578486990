import Footer from "../_generic/Footer";
import React, { useState } from "react";
import { Layout } from "../_generic/Layout";
import { formatCurrency } from "../../../utils/formatCurrency";
import { formatNumberCompact } from "../../../utils/formatNumber";
import { Factory, ShieldCheck, Leaf, Heart, Award, Star } from "lucide-react";

const ProductCard = ({ product }) => {
    return (
        <div className="border border-eyvagray-400 rounded-[10px] flex flex-col bg-white font-ibm h-full">
            <div>
                <div className="text-xs mb-2 px-4 pt-4">
                    <span className="flex flex-row items-center gap-1">
                        <Star className="size-3 fill-black" />
                        <span className="font-medium text-black">
                            {product.rating || "-"}
                        </span>{" "}
                        <span className="font-normal text-eyvagray-500">
                            ({formatNumberCompact(product.reviews)} REVIEWS)
                        </span>
                    </span>
                </div>
                <div className="flex-grow flex items-center justify-center py-2 border-b border-eyvagray-400">
                    <img
                        src={product.imgURL}
                        alt={product.name}
                        className="size-20"
                    />
                </div>
            </div>
            <div className="px-4 pb-4 flex flex-col justify-between h-full">
                <div className="mt-2">
                    <div className="text-[10px] font-normal font-inter text-eyvagray-600">
                        {product.brand}
                    </div>
                    <div className="font-medium font-inter text-sm truncate">
                        {product.name}
                    </div>
                    <div className="text-[10px] font-normal font-ibm text-eyvagray-500">
                        {product.minPrice === product.maxPrice
                            ? formatCurrency(product.minPrice, "EUR")
                            : `${formatCurrency(
                                  product.minPrice,
                                  "EUR",
                              )} - ${formatCurrency(product.maxPrice, "EUR")}`}
                    </div>
                </div>
                <div className="flex flex-wrap gap-1 mt-6 max-h-[60px]">
                    {product?.tags?.slice(0, 2).map((tag, tagIndex) => {
                        // Get the icon component from the mapping or default to Factory
                        // const IconComponent =
                        //     iconComponents[tag.icon.toLowerCase()] || Leaf;

                        console.log(tag, product);

                        return (
                            <span
                                key={tagIndex}
                                className="text-[10px] px-2 py-0 border-eyvagray-600 rounded-full border font-normal bg-zinc-200 flex items-center gap-1 text-eyvagray-600 font-ibm mb-1 uppercase max-w-36"
                            >
                                <p
                                    className="material-icons"
                                    style={{
                                        fontSize: "12px",
                                        maxWidth: "12px",
                                    }}
                                >
                                    {tag.icon.toLowerCase()}
                                </p>
                                <p className="uppercase font-ibm text-[10px] line-clamp-1">
                                    {tag.name || "VEGAN"}
                                </p>
                            </span>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export const ProductGrid = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    const imgURLs = [
        "https://s3-alpha-sig.figma.com/img/81f5/4d66/700700e82e249d3b066f8e406a3a6964?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=GhQ1sADxR3tcnWCEE49obTpDI4oQHbmitMZGZPzFkhm1wgeuk1xm6KWuHhGhVkbWgBzDBEQhGub4BBGLpqOFezEqp-9yXKEX8yGhuNcwNy0qv-Cx-WKtPFq4d0g~TOWI4~bhTbRyow1rrYmHEp79HLmnTvZtmCQBN2pb~AO1TrN80OLG-19QgjgiRiGZalZ9S5GUODT9whH7oZAUE9AW9NER5ZIoOl-lmeXKzcyfryLezo2sPPrgz2Wf2FAbLFFo90-IEcHM0LtbP6JDV3jBwoA-5JqWhNqyWglJfIyCc3WZWpvJ8c-OYPdFzAqx56QvCv1RHsBzhwhB4N4Y09PTqw__",

        "https://s3-alpha-sig.figma.com/img/e318/449a/dc63d48b8f1d71e4fa19e5247c27f248?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Ug2cMvjkU8zLlhezHDrwlvajrbl4aEsP1Heb~TB5w1aGJpQLtLe6EqijrG2BHZq0fdfhpXUe-SxnB7KCi8~SEbXpK2WROgL0Nf22fEcYXd7xi3PeWrk5bNg6oq-iTLwSyOeb6RRYVHaM6n1cW8cXRwznXUxsmmU6jBAEjL1Cf3ycjocyNO9O0NG3u1oDunvsHPAPrJuNdwR89-XmrstPSbBetgOtnq82YrycQeLG19iDrI~ie7-X7vjxPnYeI7-0TsQnT1PVEkKUhg4N2zqW239jSMov1RAexslBr57mz5J4e11nFNw2hdqZDhfCno9XIBKESfJkm1jS51Rxtj-WZA__",

        "https://s3-alpha-sig.figma.com/img/2b3d/5697/aa45e71de4be009772cc4ae26778c1fd?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=PUl~ipkS1mZs-7mQjKOfJbAxRfd2Clj7XPgu7ictafBEPvr3LJzMLyB0JyLJYxfItU~iidHE2AdgcgQ4dCQg-LFT7ZrLyrIECqixVeNj2VnQEvclkkBRTO7LTpnpv7PvPg~G1oxmaNCMhIjYrrL9EmO-HcLNQi8c1fl3S3pl0Iv48e6aRpy-O-aJcnDxOw5gtDyWjGd9VVDBKVo0zi4p7cWo~bzxGvUHxAEZAv~UEQn4eBBXl4ZNSyY7ynCBjJgb1UNpH0X63wQ3pZbEP07YSbAe1Usn25MuwX3dT-2fe4DiC3DoGLPl3aMUTtMGcD1GIpy~DNOw37V3G5f-3iPq3Q__",

        "https://s3-alpha-sig.figma.com/img/a89b/ab97/e4c4bd69359a8c193e0747c6a072a1e0?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=cPw9g4n99d2g1cyUGb-E0aUxT-WKMsGjmQz2tuQ3KZA0POZ82sz0mgXKPNZxbK6LDo2jYFtSHfFYdhHpTsQKB4MJNInz7Qq56zF7Lk15v8koGtBZMk3O7TRpxoEZnthEZvQqbR61~hSCXwC14237shUw5BODLDnFvi~5gx5Tndd5ACg9U9I85P2vmg302YobY0kF6tAhXIxYG2Q26wbhAlEJPT9fJAbTQ7GSbOCkk2lGBNKYNn1zOpHSIyYlN~zkLRZKgp~QR07OlCy0x4OtKwqldV3T93-L1R4JIShD1z7wHVQ4zA1TBWCb2-StGcfzts8jY14F4PY-emaYMkwlgg__",

        "https://s3-alpha-sig.figma.com/img/63c5/1be3/8c927af51f8ddf8fedad8238e87d0336?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=s5dNyOKpbuZxn-Cp5hjaNL1uyVXnFxb5aBsVrMSLXzMgUAOEjahBKcrQyBTxgpltD3RBPuBZy0mDpgGxNJE8TlEoNYNKQmR1rWGaSnKlu1G8jI2hr~P3nRJLibFBy-KYrMbhMhY2JJFjt1BlsS9xxs57McP-0ZACvqG30JvE50yDQsaccoZhfVkyA8R-NY6b41hXFPS956-2BQnuw3FDEmgOUJm3KhSvO8XDSdaWXS27MppF7Aa0KGDoWp9HXtQNfwWlOVXmgcNTaZjWpZOYoDStfZGPyucPpEXeb3LMrnuQiRTE1nOmSiAy5hR~0uaKdQ32DZ~NJ44hTwQ~d-miYg__",
    ];

    const fillProducts = () => {
        const products = [];
        for (let i = 0; i < 46; i++) {
            products.push({
                name: "ROCHE-POSAY Lipikar Baume AP+M",
                brand: "La Roche-Posay",
                minPrice: 20.0,
                maxPrice: 35.0,
                rating: 4.6,
                reviews: 344,
                imgURL: imgURLs[i % imgURLs.length],
                tags: [
                    { name: "VEGAN", icon: "water_drop" },
                    { name: "CRUELTY FREE", icon: "water_drop" },
                ],
            });
        }
        return products;
    };

    const firstRowProducts = [
        {
            name: "ROCHE-POSAY Lipikar Baume AP+M",
            brand: "La Roche-Posay",
            minPrice: 20.0,
            maxPrice: 35.0,
            rating: 4.6,
            reviews: 344,
            imgURL: imgURLs[0],
            tags: [
                { name: "VEGAN", icon: "water_drop" },
                { name: "CRUELTY FREE", icon: "water_drop" },
            ],
        },
        {
            name: "ROCHE-POSAY Lipikar Baume AP+M",
            brand: "La Roche-Posay",
            minPrice: 20.0,
            maxPrice: 35.0,
            rating: 4.6,
            reviews: 344,
            imgURL: "https://s3-alpha-sig.figma.com/img/2b3d/5697/aa45e71de4be009772cc4ae26778c1fd?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=PUl~ipkS1mZs-7mQjKOfJbAxRfd2Clj7XPgu7ictafBEPvr3LJzMLyB0JyLJYxfItU~iidHE2AdgcgQ4dCQg-LFT7ZrLyrIECqixVeNj2VnQEvclkkBRTO7LTpnpv7PvPg~G1oxmaNCMhIjYrrL9EmO-HcLNQi8c1fl3S3pl0Iv48e6aRpy-O-aJcnDxOw5gtDyWjGd9VVDBKVo0zi4p7cWo~bzxGvUHxAEZAv~UEQn4eBBXl4ZNSyY7ynCBjJgb1UNpH0X63wQ3pZbEP07YSbAe1Usn25MuwX3dT-2fe4DiC3DoGLPl3aMUTtMGcD1GIpy~DNOw37V3G5f-3iPq3Q__",
            tags: [
                { name: "VEGAN", icon: "water_drop" },
                { name: "CRUELTY FREE", icon: "water_drop" },
            ],
        },
        {
            name: "ROCHE-POSAY Lipikar Baume AP+M",
            brand: "La Roche-Posay",
            minPrice: 20.0,
            maxPrice: 35.0,
            rating: 4.6,
            reviews: 344,
            imgURL: imgURLs[3],
            tags: [
                { name: "VEGAN", icon: "water_drop" },
                { name: "CRUELTY FREE", icon: "water_drop" },
            ],
        },
        {
            name: "ROCHE-POSAY Lipikar Baume AP+M",
            brand: "La Roche-Posay",
            minPrice: 20.0,
            maxPrice: 35.0,
            rating: 4.6,
            reviews: 344,
            imgURL: imgURLs[4],
            tags: [
                { name: "VEGAN", icon: "water_drop" },
                { name: "CRUELTY FREE", icon: "water_drop" },
            ],
        },
    ];

    const coloursHexRatingsCount = [
        {
            colour: "Ocean Blue Yo Sup coooooooooooool",
            hex: "#0000FF",
            count: 1500,
            products: [...firstRowProducts, ...fillProducts()],
        },
        {
            colour: "Sunset Orange",
            hex: "#FFA500",
            count: 1500,
            products: [...firstRowProducts, ...fillProducts()],
        },
        {
            colour: "Lavender Mist",
            hex: "#800080",
            count: 1500,
            products: [...firstRowProducts, ...fillProducts()],
        },
        {
            colour: "Crimson Red",
            hex: "#FF0000",
            count: 1500,
            products: [...firstRowProducts, ...fillProducts()],
        },
        {
            colour: "Golden Yellow",
            hex: "#FFFF00",
            count: 1500,
            products: [...firstRowProducts, ...fillProducts()],
        },
        {
            colour: "Emerald Green",
            hex: "#00FF00",
            count: 1500,
            products: [...firstRowProducts, ...fillProducts()],
        },
        {
            colour: "Steel Gray",
            hex: "#808080",
            count: 1500,
            products: [...firstRowProducts, ...fillProducts()],
        },
        {
            colour: "Brown",
            hex: "#A52A2A",
            count: 1500,
            products: [...firstRowProducts, ...fillProducts()],
        },
        {
            colour: "Black",
            hex: "#000000",
            count: 1500,
            products: [...firstRowProducts, ...fillProducts()],
        },
        {
            colour: "White",
            hex: "#E9F9F9",
            count: 1500,
            products: [...firstRowProducts, ...fillProducts()],
        },
    ];

    const { h1 = "PRODUCT GRID", colours = coloursHexRatingsCount } =
        slideContent;

    const [selectedColour, setSelectedColour] = useState(colours[0]?.hex || "");

    const selectedColourData =
        colours.find((c) => c.hex === selectedColour) || colours[0];

    const FIRST_ROW_PRODUCTS = 4;
    const ALL_OTHER_PRODUCTS = 46;
    const MAX_PRODUCTS = FIRST_ROW_PRODUCTS + ALL_OTHER_PRODUCTS;

    return (
        <Layout showFull={showFull} className="bg-eyvalight">
            <div className="text-eyvagray-700 w-full min-h-[690px]">
                {/* Header */}
                <div className="w-full text-center py-6">
                    <h1 className="text-4xl font-normal text-black font-gosha">
                        {h1}
                    </h1>
                </div>

                {/* Main content */}
                <div className="flex">
                    {/* Color selection sidebar */}
                    <div className="w-1/5 border-eyvagray-400 font-medium font-ibm text-xs">
                        <div className="uppercase py-4 pl-5 pr-15 bg-[#EBEBEB] border-t border-b border-eyvagray-400 leading-5">
                            SELECT COLOR BELOW
                        </div>

                        {colours.map((colour, index) => (
                            <div
                                key={`${colour.hex}-${index}`}
                                className="flex items-center mb-3 cursor-pointer py-5 pl-5 pr-15 border-b border-eyvagray-400 space-x-2"
                                onClick={() => setSelectedColour(colour.hex)}
                            >
                                <input
                                    type="radio"
                                    checked={selectedColour === colour.hex}
                                    onChange={() =>
                                        setSelectedColour(colour.hex)
                                    }
                                    className="form-radio h-4 w-4"
                                />
                                <div
                                    className="size-3 rounded-sm"
                                    style={{ backgroundColor: colour.hex }}
                                />
                                <div className="text-sm font-inter font-normal flex items-center line-clamp-1 gap-2 capitalize">
                                    <p className="max-w-28 line-clamp-1">
                                        {colour.colour}
                                    </p>
                                    <span className="text-gray-400 font-ibm text-xs">
                                        ({formatNumberCompact(colour.count)})
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Product grid */}
                    <div className="w-4/5 font-medium font-ibm text-sm border-l border-eyvagray-400">
                        <div className="flex justify-between items-center px-11 py-4 bg-[#EBEBEB] border-y border-eyvagray-400 leading-5">
                            <div className="flex items-center uppercase text-xs font-ibm font-medium text-eyvagray-700">
                                <div
                                    className="h-4 w-4 rounded-full mr-2"
                                    style={{
                                        backgroundColor: selectedColourData.hex,
                                    }}
                                ></div>
                                <div>{selectedColourData.colour}</div>
                                <div className="ml-4">
                                    {formatNumberCompact(
                                        selectedColourData.count,
                                    )}{" "}
                                    PRODUCTS
                                </div>
                            </div>
                            <div className="text-gray-500 text-xs font-normal">
                                Products are sorted by closest color match.
                            </div>
                        </div>

                        {/* Product grid - single grid with different column spans */}
                        <div className="grid grid-cols-12 gap-4 py-14 px-12">
                            {selectedColourData.products
                                .slice(0, MAX_PRODUCTS)
                                .map((product, index) => {
                                    // First row (4 products) - each spans 3 columns (12/4=3)
                                    // Remaining products (6 per row) - each spans 2 columns (12/6=2)
                                    const colSpan =
                                        index < FIRST_ROW_PRODUCTS ? 3 : 2;

                                    return (
                                        <div
                                            key={`${product.name}-${index}`}
                                            className={`col-span-${colSpan}`}
                                        >
                                            {index < FIRST_ROW_PRODUCTS ? (
                                                <ProductCard
                                                    product={product}
                                                />
                                            ) : (
                                                <div className="aspect-square border border-eyvagray-400 rounded-md overflow-hidden bg-white flex items-center justify-center">
                                                    <img
                                                        src={product.imgURL}
                                                        alt={product.name}
                                                        className="size-20"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>

                        <div className="text-center text-gray-500 text-sm font-normal pb-14 font-ibm">
                            In the report, we are only able to display up to{" "}
                            {MAX_PRODUCTS} products.
                        </div>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
                withBackground={false}
                newui={true}
                dark
            />
        </Layout>
    );
};

export default ProductGrid;
