import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const Comparison = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    let {
        h1 = "ERROR",
        h2 = "ERROR",
        titleOne = "ERROR",
        imageURLOne = "ERROR",
        titleTwo = "ERROR",
        imageURLTwo = "ERROR",
    } = slideContent;

    return (
        <Layout showFull={showFull} className="bg-eyvalight">
            <div className="text-eyvagray-700 w-full min-h-[690px] p-12">
                <p className="text-4xl font-normal font-gosha leading-[44px] mb-3 uppercase">
                    {h1}
                </p>
                <p className="text-sm font-normal font-ibm leading-[24px]">
                    {h2}
                </p>

                <div className="flex flex-row items-center space-x-10 justify-center mt-5 w-full">
                    <div className="flex flex-col items-center justify-center font-ibm py-5 px-5 w-full border-eyvagray-400 border rounded-[20px] bg-white">
                        <p className="text-xs font-medium font-ibm leading-[24px] uppercase">
                            {titleOne}
                        </p>
                        <img
                            src={imageURLOne}
                            alt={titleOne}
                            className="size-[437px]"
                        />
                    </div>
                    <div className="flex flex-col items-center justify-center font-ibm py-5 px-5 w-full border-eyvagray-400 border rounded-[20px] bg-white">
                        {" "}
                        <p className="text-xs font-medium font-ibm leading-[24px] uppercase">
                            {titleTwo}
                        </p>
                        <img
                            src={imageURLTwo}
                            alt={titleTwo}
                            className="size-[437px]"
                        />
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
                withBackground={false}
                newui={true}
                dark
            />
        </Layout>
    );
};

export default Comparison;
