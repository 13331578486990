import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { ArrowRight, Globe, Search } from "lucide-react";

const Intro = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    const {
        title = "ERROR",
        description = "ERROR",
        country = "ERROR",
        category = "ERROR",
        subCategory = "",
        productType = "",
        searchTerm = "",
        etailers = "ERROR",
    } = slideContent;

    return (
        <Layout showFull={showFull} className="bg-eyvalight">
            <div className="text-eyvagray-700 w-full min-h-[690px] grid grid-cols-2">
                <div className="col-span-1 flex flex-col items-center justify-center px-28 text-center">
                    <p className="text-4xl font-normal font-gosha leading-[44px] mb-3 uppercase">
                        {title}
                    </p>
                    <p className=" font-normal font-inter text-sm leading-[24px]">
                        {description}
                    </p>
                </div>
                <div className="flex flex-col col-span-1 justify-center px-12 py-40 items-start space-y-8">
                    <div className="flex flex-col w-full">
                        <p className="font-normal leading-[24px] flex flex-row items-center space-x-2 font-inter">
                            <Globe className="w-4 h-4" />{" "}
                            <p className="text-base">{country}</p>
                        </p>
                    </div>
                    <hr className="w-full border-t border-eyvagray-700" />
                    <div className="flex flex-col w-full space-y-8 text-sm font-ibm">
                        <div className="flex flex-col">
                            <p className=" font-normal leading-[24px] flex flex-row space-x-2 text-eyvagray-500 mb-3">
                                Your filter selection
                            </p>
                            <div className="flex flex-row space-x-2 uppercase">
                                <p className=" font-normal leading-[24px] border border-eyvagray-700 rounded-full px-3">
                                    {category}
                                </p>
                                {subCategory && (
                                    <p className=" font-normal leading-[24px] flex flex-row items-center space-x-2">
                                        <ArrowRight className="w-4 h-4" />
                                        <p className="border border-eyvagray-700 rounded-full px-3">
                                            {subCategory}
                                        </p>
                                    </p>
                                )}
                                {productType && (
                                    <p className=" font-normal leading-[24px] flex flex-row items-center space-x-2">
                                        <ArrowRight className="w-4 h-4" />
                                        <p className="border border-eyvagray-700 rounded-full px-3">
                                            {productType}
                                        </p>
                                    </p>
                                )}
                            </div>
                        </div>
                        {searchTerm && (
                            <div className="flex flex-col  font-normal leading-[24px] ">
                                <p className="text-eyvagray-500 mb-3">
                                    Your advanced search input
                                </p>
                                <div className="flex flex-row">
                                    <p className="border border-eyvagray-700 rounded-full px-3 flex flex-row items-center space-x-2 uppercase">
                                        <Search className="w-4 h-4" />
                                        <p>{searchTerm}</p>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <hr className="w-full border-t border-eyvagray-700" />
                    <div className="flex flex-col w-full">
                        <p className="text-sm font-normal font-ibm leading-[24px] text-eyvagray-500 mb-3">
                            E-Tailers
                        </p>
                        <p className="text-sm font-normal font-inter leading-[24px]">
                            {etailers}
                        </p>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
                withBackground={false}
                newui={true}
                dark
            />
        </Layout>
    );
};

export default Intro;
