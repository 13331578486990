import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const TableOfContents = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    const {
        h1 = "ERROR",
        contents = [],
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/w_1000/q_auto/f_auto/v1701935339/ICE/woman_light_eye_cxzqph.png",
    } = slideContent;

    return (
        <Layout showFull={showFull} className="bg-eyvalight">
            <div className="text-eyvagray-700 w-full min-h-[690px] grid grid-cols-2">
                <div className="mt-8 col-span-1 py-[135px] px-[120px]">
                    <p className="text-4xl font-normal font-gosha leading-[44px] mb-3 uppercase">
                        {h1}
                    </p>
                    <div className="text-sm font-normal">
                        {contents.map((item, id) => (
                            <div
                                key={id}
                                className="flex items-center justify-between
                                 border-b border-eyvagray-700 text-sm font-normal font-ibm leading-[51px]
                                "
                            >
                                <p>{item.title || item}</p>
                                <p className="text-sm text-eyvagray-700">
                                    {item.page?.toString().padStart(2, "0") ||
                                        String(id + 1).padStart(2, "0")}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col col-span-1">
                    <img
                        loading="lazy"
                        srcSet={imageURL}
                        className="w-full h-full object-cover"
                        alt=""
                    />
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
                withBackground={false}
                newui={true}
                dark
            />
        </Layout>
    );
};

export default TableOfContents;
