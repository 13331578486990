import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const Analysis = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    let {
        h1 = "ERROR",
        h2 = "ERROR",
        paragraphs = [],
        title = "ERROR",
        imageURL = "ERROR",
    } = slideContent;

    return (
        <Layout showFull={showFull} className="bg-eyvalight">
            <div className="text-eyvagray-700 w-full min-h-[690px] p-12 flex flex-col">
                <div className="grid grid-cols-2 gap-10 justify-center w-full flex-grow">
                    <div className="flex flex-col text-start justify-center font-ibm">
                        <p className="text-4xl font-normal font-gosha leading-[54px] uppercase mb-3">
                            {h1}
                        </p>
                        <p className="text-sm font-normal font-ibm leading-[18px] mb-10 text-eyvagray-600">
                            {h2}
                        </p>
                        <div className="flex flex-col gap-4 overflow-y-scroll text-black max-h-[450px] font-inter pr-5">
                            {paragraphs.map((p) => (
                                <div key={p.header}>
                                    <h3 className="!text-[16px] font-medium leading-7 font-inter text-black">
                                        {p.header}
                                    </h3>
                                    <div
                                        className="!text-sm !font-normal !leading-6 markdown !font-inter text-eyvagray-700"
                                        dangerouslySetInnerHTML={{
                                            __html: p.paragraph,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center font-ibm py-5 px-5 border-eyvagray-400 border rounded-[20px] bg-white">
                        {" "}
                        <p className="text-xs font-medium font-ibm leading-[24px] uppercase">
                            {title}
                        </p>
                        <img
                            src={imageURL}
                            alt={title}
                            className="size-[490px]"
                        />
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
                withBackground={false}
                newui={true}
                dark
            />
        </Layout>
    );
};

export default Analysis;
